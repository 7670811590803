import React from "react";
import { Redirect, Route, Switch, asyncComponent } from "../../../components";
const Customers = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Route path={`${match.url}/list`} component={asyncComponent(() => import('./routes/list'))}/>
      
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Customers;
