import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Link,
  connect,
  TEXTFIELD,
  CircularProgress,
  toast,
  ToastContainer,
  Logo,
  Axios,
  PASSWORDFIELD,
  Row,
  Col,
  SUBMIT,
  IntlMessages,
} from "../components";
import { FLEET_LOGIN, DRIVER_LOGIN } from "../ApiUrl";

import {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
} from "actions/Auth";

const engSentance = {
  title: "Sign In",
  field1: "Enter Email",
  field2: "Enter Password",
  field3: "Select Login Type",
  button: "Sign In",
};

const speSentance = {
  title: "Iniciar sesión",
  field1: "Ingresar correo electrónico",
  field2: "Ingresar contraseña",
  field3: "Select Login Type",
  button: "Iniciar sesión",
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      login_type: 1,
      errorMessage: "",
      isLanguage: false,
      language: { ...engSentance },
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.props.showAuthLoader();
    const { email, password, login_type } = this.state;

    const formData = {
      email: email.trim(),
      password: password.trim(),
      login_type: login_type
    };

    Axios.post((login_type == 1) ? FLEET_LOGIN : DRIVER_LOGIN, formData)
      .then((response) => {
        const data = response.data;

        if (data.status == "failure") {
          toast.error(data.message);
        } else {
          console.log(data);
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("_id", data.data._id);
          localStorage.setItem("name", data.data.name);
          localStorage.setItem("role", (login_type == 1) ? 'FLEET' : 'DRIVER');
          localStorage.setItem("user_id", data.data.token);
          this.props.userSignInSuccess(data.data.token);
          toast.success("Login successfully.");
          setTimeout(() => {
            this.props.history.push("/app/dashboard/index");
          }, 1000);
        }
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        console.log(err);
        let error = err.response;
        if (error !== undefined) {
          toast.error(error.data.message);
        } else {
          toast.error("server error, Please try after some time.");
        }
        this.props.hideAuthLoader();
      });
  };

  changeLanguage = () => {
    this.setState((pre, props) => ({
      isLanguage: !pre.isLanguage,
      language: !pre.isLanguage ? { ...speSentance } : { ...engSentance },
    }));
  };

  render() {
    const { email, password, language } = this.state;
    const { loader } = this.props;
    var diabled = !email || !password ? true : "";
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={Logo} alt="jambo" title="jambo" className="logo" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <Grid>
                <Grid item md={6}>
                  {" "}
                  <h1>{language.title}</h1>
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  style={{ float: "right", marginTop: "-35px" }}
                >
                  {" "}
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>ENG</Grid>
                      <Grid item>
                        <AntSwitch
                          checked={this.state.isLanguage}
                          onChange={this.changeLanguage}
                          name="checkedC"
                        />
                      </Grid>
                      <Grid item>SPN</Grid>
                    </Grid>
                  </Typography>
                </Grid>
               */}
              </Grid>
            </div>

            <Row>
              <form onSubmit={(e) => this.loginUser(e)}>
                <Col xm="12">
                  <TEXTFIELD
                    label={language.field1}
                    fullWidth
                    name="email"
                    onChange={this.handleChange}
                    value={email}
                    errors={{}}
                  />
                </Col>
                <Col xm="12">
                  <PASSWORDFIELD
                    label={language.field2}
                    fullWidth
                    name="password"
                    onChange={this.handleChange}
                    value={password}
                    errors={{}}
                  />
                </Col>
                <Col xm="12">
                  <select name="login_type" onChange={this.handleChange}>
                    <option value={1} selected>Fleet</option>
                    <option value={2}>Driver</option>
                  </select>
                </Col>
                <Col xm="12" md="7">
                  <SUBMIT title={language.button} disabled={diabled} />
                </Col>
              </form>
            </Row>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(SignIn);
