import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const subMenuLi = document.querySelectorAll(".sub-menu > li");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function(event) {
        event.stopPropagation();
      };
    }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          // console.log("parentLi",parentLi);
          //alert('ok');
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
        event.stopPropagation();
      };
    }

    const activeLi = document.querySelector('a[href="#' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul

      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
        console.log(this.closest(activeNav, "li"));
      } else {
        this.closest(activeLi, "li").classList.add("open");
        console.log("this", this.closest(activeNav, "li"));
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          {localStorage.getItem("role") == 'FLEET' ?
          <>
            <li>
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.dashboard" />
              </span>
            </NavLink>
            </li>
            <li>
              <NavLink to="/app/drivers/list">
                <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.drivers" />
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-swap-vertical-circle zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.trips" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon cbutton" to="/app/trips/list">
                    <i className="zmdi zmdi-receipt" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.all" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/trips/completed"
                  >
                    <i className="zmdi zmdi-receipt" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.completed" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon cbutton" to="/app/trips/new">
                    <i className="zmdi zmdi-receipt" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.canceled" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/app/earning/list">
              <i className="zmdi zmdi-copy zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="Earnings" />
                </span>
              </NavLink>
            </li>
            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="Invoices" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/invoice/list">
                  <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="eCAB to Fleet" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/invoice/partnerToCustomer">
                  <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="Partner to customer" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </>
          : 
          <>
            {/* Driver Sidebar content */}
            {/* Dashboard */}
            <li>
              <NavLink to="/app/dashboard/index">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </NavLink>
            </li>

            {/* Trips */}
            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-swap-vertical-circle zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.trips" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon cbutton" to="/app/driverTrips/list">
                    <i className="zmdi zmdi-receipt" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.all" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/driverTrips/completed"
                  >
                    <i className="zmdi zmdi-receipt" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.completed" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon cbutton" to="/app/driverTrips/new">
                    <i className="zmdi zmdi-receipt" />
                    <span className="nav-text">
                      <IntlMessages id="sidebar.canceled" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* Driver earnings */}

            <li>
              <NavLink to="/app/driverEarnings/list">
              <i className="zmdi zmdi-copy zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="Earnings" />
                </span>
              </NavLink>
            </li>

            {/* Manage Profile */}

            <li>
              <NavLink to="/app/settings/profile">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="Manage Profile" />
                </span>
              </NavLink>
            </li>
          </>
          }
          

        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
